import Vue from "vue/dist/vue.js";
import VueRouter from "vue-router";

import store from "@/store/index.js";
const LoginComercial = () =>
  import(
    /* webpackChunkName: "loginComercial" */ "@/views/LoginComercial/Index.vue"
  );
const LoginClient = () =>
  import(
    /* webpackChunkName: "loginCliente" */ "@/views/LoginClient/Index.vue"
  );
const ClientLobby = () =>
  import(
    /* webpackChunkName: "lobbyCliente" */ "@/views/ClientLobby/Index.vue"
  );
const ShowRoomComercial = () =>
  import(
    /* webpackChunkName: "showroomComercial" */ "@/views/ShowRoomComercial/Index.vue"
  );
const ShowRoomClient = () =>
  import(
    /* webpackChunkName: "showroomCliente" */ "@/views/ShowRoomClient/Index.vue"
  );
const Panel = () =>
  import(/* webpackChunkName: "dahsboardPanel" */ "@/views/Panel/Index.vue");
const PasswordRecovery = () =>
  import(
    /* webpackChunkName: "passwordRecovery" */ "@/views/PasswordRecovery/Index.vue"
  );
const ShowroomsList = () =>
  import(
    /* webpackChunkName: "dashboardList" */ "@/views/ShowroomsList/Index.vue"
  );

Vue.use(VueRouter);

const routes = [
  {
    path: "/login/comercial",
    name: "LoginComercial",
    component: LoginComercial
  },
  {
    path: "/recoveryPassword",
    name: "PasswordRecovery",
    component: PasswordRecovery
  },
  {
    path: "/",
    name: "LoginClient",
    component: LoginClient
  },
  {
    path: "/clientLobby",
    name: "ClientLobby",
    component: ClientLobby,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/showRoomComercial/:id",
    name: "ShowRoomComercial",
    component: ShowRoomComercial,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/showRoomClient",
    name: "ShowRoomClient",
    component: ShowRoomClient,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dashboard/panel",
    name: "Dashboard/Panel",
    component: Panel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dashboard/showroomsList",
    name: "Dashboard/ShowroomsList",
    component: ShowroomsList,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.isAuthenticated) {
      next();
    } else {
      next({ name: "LoginClient" });
    }
  } else {
    next();
  }
});
export default router;
