import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config.js";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  }
};

export default ApiService;

export const AuthService = {
  loginComercial(credentials) {
    return ApiService.post("users/login/", credentials);
  },
  verifyVerificationToken(token) {
    return ApiService.post("/users/verify/", {
      token
    });
  },
  loginClientWithEmail(email) {
    return ApiService.post("/salesrooms/verify_attendant_email/", {
      email
    });
  },
  verifyAttendantToken(access_token) {
    return ApiService.post("/salesrooms/verify_attendant_token/", {
      access_token
    });
  },
  getRecoveryToken(email) {
    return ApiService.post("/users/get_recovery_token/", {
      email
    });
  },
  verifyRecoveryToken(recovery_token) {
    return ApiService.post("/users/verify_recovery_token/", {
      recovery_token
    });
  },
  setNewPassword(password1, password2, recovery_token) {
    return ApiService.post("/users/set_new_password/", {
      password: password1,
      password_confirmation: password2,
      recovery_token
    });
  }
};

export const CollectionsService = {
  get() {
    let url = `/series/?expand=videos&expand=images&expand=sheets&expand=products&expand=brochures`;
    return ApiService.query(url);
  },
  simpleSearch(query) {
    let url = `/series/?expand=videos&expand=images&expand=sheets&expand=products&expand=brochures`;
    return ApiService.query(`${url}&search=${query}`);
  },
  avancedSearch(query, params) {
    let url = `/series/?expand=videos&expand=images&expand=sheets&expand=products&expand=brochures`;
    if (query) {
      url = url.concat(`&search=${query}`);
    }
    Object.entries(params).forEach(entry => {
      const [key, value] = entry;
      if (value !== undefined) {
        url = url.concat(`&${key}=${value}`);
      }
    });
    return ApiService.query(url);
  }
};
export const ProductFormService = {
  async getColors() {
    ApiService.setHeader();
    return await ApiService.query("/products_colors/");
  },
  async getFamilies() {
    ApiService.setHeader();
    return await ApiService.query("/products_families/");
  },
  async getFeatures() {
    ApiService.setHeader();
    return await ApiService.query("/products_features/");
  },
  async getFormatCM() {
    ApiService.setHeader();
    return await ApiService.query("/products_format_cm/");
  },
  async getNuances() {
    ApiService.setHeader();
    return await ApiService.query("/products_nuances/");
  },
  async getSolutions() {
    ApiService.setHeader();
    return await ApiService.query("/products_solutions/");
  }
};

export const SalesRoomService = {
  getSalesRoomComercial(id) {
    ApiService.setHeader();
    return ApiService.get(
      `/salesrooms/${id}`,
      "?expand=series_viewed&expand=viewed_products&expand=scheduled_series&expand=attendants"
    );
  },
  getToBePresentedShowrooms() {
    ApiService.setHeader();
    let params = {
      expand: "seller,attendants",
      ordering: "-id",
      presented: false,
      is_showing: false,
      expired: false
    };
    return ApiService.query("/salesrooms/", { params });
  },
  getSupervisorSalesRoomList(page, search) {
    ApiService.setHeader();
    let params = {
      expand: "seller",
      ordering: "-id",
      page,
      search
    };
    return ApiService.query("/supervised_salesrooms/", { params });
  },
  getSalesRoomList(page, search) {
    ApiService.setHeader();
    let params = {
      expand: ["attendants", "seller"],
      ordering: "-id",
      page,
      search
    };
    return ApiService.query("/salesrooms/", { params });
  },
  setShowingContent(id, content) {
    ApiService.setHeader();
    return ApiService.post(`/salesrooms/${id}/showing_content/`, {
      showing_content: `${JSON.stringify(content)}`
    });
  },
  createSalesRoom(data) {
    ApiService.setHeader();
    return ApiService.post("/salesrooms/", data);
  },
  startSalesRoom(id) {
    ApiService.setHeader();
    return ApiService.post(`/salesrooms/${id}/start/`);
  },
  endSalesRoom(id) {
    ApiService.setHeader();
    return ApiService.post(`/salesrooms/${id}/end/`);
  },
  getFullSalesRoomData(id) {
    ApiService.setHeader();
    let params = {
      expand: [
        "attendants",
        "scheduled_series",
        "viewed_series",
        "series",
        "viewed_products"
      ]
    };
    return ApiService.query(`/salesrooms/${id}/`, { params });
  },
  getFullSupervisorSalesRoomData(id) {
    ApiService.setHeader();
    let params = {
      expand: [
        "attendants",
        "scheduled_series",
        "viewed_series",
        "series",
        "viewed_products"
      ]
    };
    return ApiService.query(`/supervised_salesrooms/${id}/`, { params });
  },
  updateSalesRoom(id, data) {
    ApiService.setHeader();
    return ApiService.patch(`/salesrooms/${id}/`, data);
  },
  inviteClient(id, data) {
    return ApiService.post(`/salesrooms/${id}/invite/`, data);
  },
  attendantJoin(id) {
    return ApiService.post(`/salesrooms/${id}/join/`, {
      attendant_token: `${JwtService.getToken()}`
    });
  },
  attendantExit(id) {
    return ApiService.post(`/salesrooms/${id}/exit/`, {
      attendant_token: `${JwtService.getToken()}`
    });
  },
  deactivateSalesRoom(id) {
    return ApiService.delete(`/salesrooms/${id}/`);
  }
};
