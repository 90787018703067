import { SET_LOGO, SET_CLEAR_STATE } from "./mutations.type";
import { THEME, RESET_STATE } from "./actions.type";
import colorService from "@/common/color.service";

const getDefaultState = () => {
  return {
    themeName: "sgp"
  };
};

const state = {
  themeName: "sgp"
};
const getters = {
  getThemeName(state) {
    return state.themeName;
  }
};
const actions = {
  [THEME]({ commit }, theme) {
    if (theme) {
      colorService.saveTheme(theme);
      commit(SET_LOGO, `${theme}`);
    }
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};
const mutations = {
  [SET_LOGO](state, url) {
    state.themeName = url;
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  }
};

export default { state, getters, actions, mutations };
