import { CollectionsService } from "@/common/api.service.js";
import ApiService from "@/common/api.service.js";
import {
  FETCH_COLLECTIONS,
  FETCH_FILTERED_COLLECTIONS,
  PAGINATION,
  RESET_STATE
} from "@/store/actions.type";
import {
  SET_IS_LOADING_NEXT_PAGE,
  SET_IS_LOADING_COLLECTIONS,
  SET_FILTERED_COLLECTIONS,
  SET_COLLECTIONS,
  SET_NEXT_COLLECTION_PAGE,
  SET_CLEAR_STATE,
  SET_FILTER_NEXT_COLLECTION_PAGE
} from "@/store/mutations.type";

const getDefaultState = () => {
  return {
    isLoadingNextPage: false,
    collections: {},
    filter: {
      selects: {
        features: [],
        families: [],
        colors: [],
        formatCM: [],
        nuances: [],
        solutions: []
      },
      query: ""
    },
    filteredCollections: [],
    isLoadingFilteredCollections: false
  };
};

const state = {
  isLoadingNextPage: false,
  collections: {},
  filter: {
    selects: {
      features: [],
      families: [],
      colors: [],
      formatCM: [],
      nuances: [],
      solutions: []
    },
    query: ""
  },
  filteredCollections: [],
  isLoadingFilteredCollections: false,
  nextPageFiltered: null,
  nextPage: null
};

const getters = {
  collections(state) {
    return state.collections;
  },
  isLoadingNextPage() {
    return state.isLoadingNextPage;
  },
  isLoadingFilteredCollections(state) {
    return state.isLoadingFilteredCollections;
  },
  filteredCollections(state) {
    return state.filteredCollections;
  },
  nextPageFiltered(state) {
    return state.nextPageFiltered;
  },
  nextPage(state) {
    return state.collections.next;
  }
};
const actions = {
  [FETCH_FILTERED_COLLECTIONS]({ commit }, { filterQuery, query }) {
    if (filterQuery) {
      commit(SET_IS_LOADING_COLLECTIONS, true);
      return CollectionsService.avancedSearch(query, filterQuery)
        .then(({ data }) => {
          commit(SET_IS_LOADING_COLLECTIONS, false);
          commit(SET_FILTERED_COLLECTIONS, data);
          return data.results;
        })
        .catch(() => {
          commit(SET_IS_LOADING_COLLECTIONS, false);
        });
    }
    commit(SET_IS_LOADING_COLLECTIONS, true);
    return CollectionsService.simpleSearch(query)
      .then(({ data }) => {
        commit(SET_IS_LOADING_COLLECTIONS, false);
        commit(SET_FILTERED_COLLECTIONS, data);
      })
      .catch(() => {
        commit(SET_IS_LOADING_COLLECTIONS, false);
      });
  },
  [FETCH_COLLECTIONS]({ commit }) {
    return CollectionsService.get()
      .then(({ data }) => {
        commit(SET_COLLECTIONS, data);
      })
      .catch(() => {});
  },
  [PAGINATION]({ commit }, payload) {
    commit(SET_IS_LOADING_NEXT_PAGE, true);
    return ApiService.query(payload.next)
      .then(({ data }) => {
        payload.isFilter
          ? commit(SET_FILTER_NEXT_COLLECTION_PAGE, data)
          : commit(SET_NEXT_COLLECTION_PAGE, data);
        commit(SET_IS_LOADING_NEXT_PAGE, false);
      })
      .catch(error => {
        commit(SET_IS_LOADING_NEXT_PAGE, false);
        throw new Error(error);
      });
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};

const mutations = {
  [SET_IS_LOADING_COLLECTIONS](state, isLoading) {
    state.isLoadingFilteredCollections = isLoading;
  },
  [SET_IS_LOADING_NEXT_PAGE](state, isLoading) {
    state.isLoadingNextPage = isLoading;
  },
  [SET_FILTERED_COLLECTIONS](state, { results, next }) {
    state.filteredCollections = results;
    state.nextPageFiltered = next;
  },
  [SET_COLLECTIONS](state, collections) {
    state.collections = collections;
  },
  [SET_NEXT_COLLECTION_PAGE](state, newCollections) {
    state.collections.results = state.collections.results.concat(
      newCollections.results
    );
    state.collections.next = newCollections.next;
  },
  [SET_FILTER_NEXT_COLLECTION_PAGE](state, newCollections) {
    state.filteredCollections = state.filteredCollections.concat(
      newCollections.results
    );
    state.nextPageFiltered = newCollections.next;
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  }
};

export default { state, getters, actions, mutations };
