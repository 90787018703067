import { SalesRoomService } from "@/common/api.service.js";
import {
  FETCH_SUPERVISOR_SEARCH_SHOWROOM_LIST,
  RESET_STATE
} from "./actions.type";
import {
  ADD_FETCHED_SEARCH_DATA_TO_SUPERVISOR_SHOWROOM_LIST,
  RESET_SUPERVISOR_SEARCH,
  SET_CLEAR_STATE
} from "./mutations.type";

const getDefaultState = () => {
  return {
    supervisorSearchShowroomList: [],
    supervisorSearchShowroomListCount: 0,
    supervisorSearchShowroomListPage: 1,
    supervisorSearchField: null,
    supervisorSearchShowroomListFetchStatus: "no-initial-fetch"
  };
};

const state = {
  supervisorSearchShowroomList: [],
  supervisorSearchShowroomListCount: 0,
  supervisorSearchShowroomListPage: 1,
  supervisorSearchField: null,
  supervisorSearchShowroomListFetchStatus: "no-initial-fetch"
};

const getters = {
  supervisorSearchShowroomList(state) {
    return state.supervisorSearchShowroomList;
  },
  supervisorSearchShowroomListFetchStatus() {
    return state.supervisorSearchShowroomListFetchStatus;
  },
  supervisorSearchShowroomListCount() {
    return state.supervisorSearchShowroomListCount;
  }
};

const actions = {
  async [FETCH_SUPERVISOR_SEARCH_SHOWROOM_LIST](
    { commit, state, dispatch },
    { page, search }
  ) {
    page = page || 1;
    if (search != state.supervisorSearchField)
      commit(RESET_SUPERVISOR_SEARCH, search);

    if (
      state.supervisorSearchShowroomListFetchStatus === "no-more-data" ||
      page < state.supervisorSearchShowroomListPage
    )
      return state.supervisorSearchShowroomList;
    let response = await SalesRoomService.getSupervisorSalesRoomList(
      state.supervisorSearchShowroomListPage,
      state.supervisorSearchField
    );
    commit(ADD_FETCHED_SEARCH_DATA_TO_SUPERVISOR_SHOWROOM_LIST, response);
    if (page >= state.supervisorSearchShowroomListPage)
      await dispatch(FETCH_SUPERVISOR_SEARCH_SHOWROOM_LIST, page);
    return state.supervisorSearchShowroomList;
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};

const mutations = {
  [ADD_FETCHED_SEARCH_DATA_TO_SUPERVISOR_SHOWROOM_LIST](state, response) {
    /*  set showroom list and manage pagination data */
    let data = response.data;

    if (state.supervisorSearchShowroomListFetchStatus === "no-initial-fetch") {
      state.supervisorSearchShowroomListFetchStatus = "data-fetched";
    }

    state.supervisorSearchShowroomListCount = data.count;

    state.supervisorSearchShowroomListPage =
      state.supervisorSearchShowroomListPage + 1;

    if (!data.next)
      state.supervisorSearchShowRoomListFetchStatus = "no-more-data";

    const dateOrNull = str => (str ? new Date(str) : null);
    let showrooms = data.results.map(showroom => {
      let status = getStatus(showroom);

      return {
        ...showroom,
        effective_start: dateOrNull(showroom.effective_start),
        effective_end: dateOrNull(showroom.effective_end),
        scheduled_for: dateOrNull(showroom.scheduled_for),
        status
      };
    });
    state.supervisorSearchShowroomList = [
      ...state.supervisorSearchShowroomList,
      ...showrooms
    ];
  },
  [RESET_SUPERVISOR_SEARCH](state, search) {
    state.supervisorSearchField = search;
    state.supervisorSearchShowroomList = [];
    state.supervisorSearchShowroomListCount = 0;
    state.supervisorSearchShowroomListPage = 1;
    state.supervisorSearchShowroomListFetchStatus = "no-initial-fetch";
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

function getStatus(showroom) {
  let status = null;
  if (showroom.presented) status = "Presentado";
  else if (showroom.is_showing) status = "En presentación";
  else if (showroom.is_prepared) status = "Preparado";
  else if (showroom.to_be_prepared) status = "Por preparar";
  else if (showroom.expired) status = "Caducado";
  return status;
}
