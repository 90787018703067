import { AuthService } from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import {
  LOGIN_COMERCIAL,
  LOGOUT,
  VERIFY_ATTENDANT_EMAIL,
  VERIFY_ATTENDANT_TOKEN,
  THEME,
  RESET_STATE
} from "./actions.type";

import {
  SET_AUTH,
  SET_IS_LOADING_AUTH,
  PURGE_AUTH,
  SET_ERROR_AUTH,
  SET_CLEAR_STATE
} from "./mutations.type";

const getDefaultState = () => {
  return {
    isLoadingAuth: false,
    errorAuth: null,
    isAuthenticated: !!JwtService.getToken(),
    user: {}
  };
};
const state = {
  isLoadingAuth: false,
  errorAuth: null,
  isAuthenticated: !!JwtService.getToken(),
  user: {},
  brand: ""
};

const getters = {
  isLoadingAuth(state) {
    return state.isLoadingAuth;
  },
  errorAuth(state) {
    return state.errorAuth;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  user(state) {
    return state.user;
  }
};

const actions = {
  [LOGIN_COMERCIAL]({ commit }, credentials) {
    commit(SET_IS_LOADING_AUTH, true);
    return AuthService.loginComercial(credentials)
      .then(({ data }) => {
        commit(SET_AUTH, data);
        commit(SET_IS_LOADING_AUTH, false);
        return data;
      })
      .catch(error => {
        commit(SET_IS_LOADING_AUTH, false);
        commit(SET_ERROR_AUTH, error);
      });
  },
  [LOGOUT]({ commit }) {
    commit(PURGE_AUTH);
    commit(SET_CLEAR_STATE);
  },
  [VERIFY_ATTENDANT_EMAIL]({ commit }, email) {
    commit(SET_IS_LOADING_AUTH, true);
    return AuthService.loginClientWithEmail(email)
      .then(({ data }) => {
        this.dispatch(THEME, data.salesroom.seller.brand.toLowerCase());
        commit(SET_AUTH, data);
        commit(SET_IS_LOADING_AUTH, false);
        this.dispatch(THEME, data.salesroom.seller.brand.toLowerCase());
        return data;
      })
      .catch(error => {
        commit(SET_IS_LOADING_AUTH, false);
        commit(SET_ERROR_AUTH, error);
        return error;
      });
  },
  [VERIFY_ATTENDANT_TOKEN]({ commit }, access_token) {
    commit(SET_IS_LOADING_AUTH, true);
    return AuthService.verifyAttendantToken(access_token)
      .then(({ data }) => {
        this.dispatch(THEME, data.salesroom.seller.brand.toLowerCase());
        commit(SET_AUTH, data);
        commit(SET_IS_LOADING_AUTH, false);
        return data;
      })
      .catch(error => {
        commit(SET_ERROR_AUTH, error);
        commit(SET_IS_LOADING_AUTH, false);
        return error;
      });
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};
const mutations = {
  [SET_IS_LOADING_AUTH](state, isLoading) {
    state.isLoadingAuth = isLoading;
  },
  [SET_AUTH](state, data) {
    state.errorAuth = null;
    state.user = data.user;
    const { access_token } = data;
    JwtService.saveToken(access_token);
    state.isAuthenticated = !!JwtService.getToken();
  },
  [PURGE_AUTH]() {
    state.errorAuth = null;
    JwtService.destroyToken();
    state.isAuthenticated = !!JwtService.getToken();
  },
  [SET_ERROR_AUTH](state, error) {
    const {
      non_field_errors,
      access_token,
      cameOut,
      date,
      message
    } = error.response.data;
    if (cameOut) {
      state.errorAuth = "El usuario ya no puede ingresar al showroom";
    } else if (non_field_errors) {
      state.errorAuth = error.response.data.non_field_errors[0];
    } else if (access_token) {
      if (access_token.date) {
        state.errorAuth =
          access_token.access_token ||
          access_token[0] ||
          access_token.message +
            " " +
            new Date(access_token.date).toLocaleString("es");
      } else if (access_token.message) {
        state.errorAuth = access_token.message;
      } else if (access_token) {
        state.errorAuth = access_token[0];
      }
    } else if (date) {
      state.errorAuth =
        message[0] + " " + new Date(date[0]).toLocaleString("es");
    } else if (message) {
      state.errorAuth = message[0];
    }
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
