<template lang="pug">
a-config-provider(:locale="es_ES")
  router-view
</template>
<script>
import es_ES from "ant-design-vue/lib/locale-provider/es_ES";
export default {
  name: "App",
  data() {
    return {
      es_ES
    };
  }
};
</script>
<style lang="less">
:root {
  --primary-color: #76a8a0;
  --link-color: #fafafa;
  --success-color: #52c41a;
  --warning-color: #faad14;
  --error-color: #a87676;
  --info-color: #7681a8;
  --font-size-base: 14px;
  --border-radius-base: 4px;
  --gray-color: #e2e2e2;
  --black-color: #2e3133;
  --white-color: #fafafa;
  --contrast-background-color: #cbc3bb;
  --container-color: #2e3133;
  --container-text-color: #fafafa;
}
html {
  overflow: hidden !important;
}
body {
  background-color: #f0f2f5 !important;
}
.video-viewer {
  width: 720px !important;
  height: 410px;
}
.inside-container {
  color: var(--container-text-color) !important;
}
.anticon-spin {
  color: var(--primary-color) !important;
}

.search-overlay-container div:nth-child(1) > a {
  color: var(--primary-color) !important;
}

.dashboard-panel > button {
  background: var(--primary-color) !important;
}
</style>
