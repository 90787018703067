const root = document.documentElement;

export const changeTheme = themeName => {
  switch (themeName) {
    case "stn":
      root.style.setProperty("--primary-color", "#6400AB");
      root.style.setProperty("--container-color", "#fafafa");
      root.style.setProperty("--container-text-color", "#2e3133");
      root.style.setProperty("--link-color", "#6400AB");
      root.style.setProperty("--error-color", "#9A3135");
      root.style.setProperty("--success-color", "#439332");
      root.style.setProperty("--secondary-color", "#3E4551");
      break;
    case "alaplana":
      root.style.setProperty("--primary-color", "#7b7b7b");
      root.style.setProperty("--container-color", "#fafafa");
      root.style.setProperty("--container-text-color", "#2e3133");
      root.style.setProperty("--link-color", "#337ab7");
      root.style.setProperty("--error-color", "#A87676");
      root.style.setProperty("--success-color", "#439332");
      root.style.setProperty("--secondary-color", "#3E4551");
      break;
    case "keratile":
      root.style.setProperty("--primary-color", "#FBA22E");
      root.style.setProperty("--container-color", "#fafafa");
      root.style.setProperty("--container-text-color", "#2e3133");
      root.style.setProperty("--link-color", "#E87E4A");
      root.style.setProperty("--error-color", "#FF3737");
      root.style.setProperty("--success-color", "#439332");
      root.style.setProperty("--secondary-color", "#3E4551");

      break;
    default:
      root.style.setProperty("--primary-color", "#76a8a0");
      root.style.setProperty("--container-color", "#2e3133");
      root.style.setProperty("--container-text-color", "#fafafa");
      root.style.setProperty("--link-color", "#76a8a0");
      root.style.setProperty("--error-color", "#A87676");
      root.style.setProperty("--success-color", "#439332");
      root.style.setProperty("--secondary-color", "#3E4551");
  }
};

const ID_THEME_KEY = "theme";

export const getTheme = () => {
  return window.localStorage.getItem(ID_THEME_KEY);
};

export const saveTheme = theme => {
  window.localStorage.setItem(ID_THEME_KEY, theme);
};

export const destroyTheme = () => {
  window.localStorage.removeItem(ID_THEME_KEY);
};

export default { changeTheme, getTheme, saveTheme, destroyTheme };
