import { SalesRoomService } from "@/common/api.service.js";
import {
  FETCH_SHOWROOM_COMERCIAL,
  RESET_SHOWROOM_COMERCIAL,
  RESET_STATE
} from "./actions.type";
import {
  SET_IS_LOADING_SHOWROOM_COMERCIAL,
  SET_SHOWROOM_COMERCIAL,
  SET_ERROR_SHOWROOM_COMERCIAL,
  SET_CLEAR_STATE,
  PATCH_SHOWROOM_DATA
} from "./mutations.type";

const getDefaultState = () => {
  return {
    showRoomComercial: {
      attendants: []
    },
    isLoadingShowRoomComercial: false,
    errorShowRoomComercial: null
  };
};

const state = {
  showRoomComercial: {
    attendants: []
  },
  isLoadingShowRoomComercial: false,
  errorShowRoomComercial: null
};

const getters = {
  showRoomComercial(state) {
    return state.showRoomComercial;
  },
  isLoadingShowRoomComercial(state) {
    return state.isLoadingShowRoomComercial;
  }
};

const actions = {
  [FETCH_SHOWROOM_COMERCIAL]({ commit }, id) {
    commit(SET_IS_LOADING_SHOWROOM_COMERCIAL, true);
    return SalesRoomService.getSalesRoomComercial(id)
      .then(({ data }) => {
        if (!data.effective_start) {
          // SalesRoomService.startSalesRoom(id);
        }
        commit(SET_SHOWROOM_COMERCIAL, data);
        commit(SET_IS_LOADING_SHOWROOM_COMERCIAL, false);
      })
      .catch(() => {
        commit(SET_IS_LOADING_SHOWROOM_COMERCIAL, false);
        commit(SET_ERROR_SHOWROOM_COMERCIAL);
      });
  },
  async [RESET_SHOWROOM_COMERCIAL]({ commit, rootState }) {
    let data = (await SalesRoomService.endSalesRoom(state.showRoomComercial.id))
      .data;
    console.clear();
    commit(PATCH_SHOWROOM_DATA, [state.showRoomComercial.id, data, rootState]);
    commit(SET_SHOWROOM_COMERCIAL, { scheduled_series: [] });
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};

const mutations = {
  [SET_IS_LOADING_SHOWROOM_COMERCIAL](state, isLoading) {
    state.isLoadingShowRoomComercial = isLoading;
  },
  [SET_SHOWROOM_COMERCIAL](state, showRoomComercial) {
    state.showRoomComercial = showRoomComercial;
    state.showRoomComercial.scheduled_series = showRoomComercial.scheduled_series.map(
      item => {
        return item.serie;
      }
    );
    state.errorShowRoomComercial = null;
  },
  [SET_ERROR_SHOWROOM_COMERCIAL](state) {
    state.errorShowRoomComercial =
      "Ocurrió un error inesperado inténtalo más tarde";
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
