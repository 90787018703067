//Collections Actions
export const FETCH_COLLECTIONS = "getCollections";
export const FETCH_FILTERED_COLLECTIONS = "getFilteredCollections";
export const PAGINATION = "pagination";
//ShowRoomComercial Actions
export const FETCH_SHOWROOM_COMERCIAL = "getShowRoomComercial";
export const RESET_SHOWROOM_COMERCIAL = "resetShowRoomComercial";

//Auth Actions
export const LOGIN_COMERCIAL = "loginComercial";
export const LOGIN_CLIENT = "loginClient";
export const LOGOUT = "logout";
export const VERIFY_ATTENDANT_EMAIL = "verifyAttendantEmail";
export const VERIFY_ATTENDANT_TOKEN = "verifyAttendantToken";

//Viewer actions
export const UPDATE_VIEWER_ITEM = "updateViewerItem";
export const UPDATE_VIEWER_MEDIA = "updateViewerMedia";

//showroom dashboard actions
export const FETCH_SHOWROOM_LIST = "fetchShowRoomList";
export const CREATE_SHOWROOM = "createShowRoom";
export const START_SHOWROOM_PRESENTATION = "startShowRoomPresentation";
export const LOAD_FULL_SHOWROOM_DATA = "loadFullShowRoomData";
export const UPDATE_SHOWROOM = "updateShowRoom";
export const DEACTIVATE_SHOWROOM = "deactivateShowRoom";
export const FETCH_TO_BE_PRESENTED_SHOWROOM_LIST =
  "fetchToBePresentedShowroomList";

//supervisor showroom list actions
export const FETCH_SUPERVISOR_SHOWROOM_LIST = "fetchSupervisorShowRoomList";

//search showroom list actions
export const FETCH_SEARCH_SHOWROOM_LIST = "fetchSearchShowroomList";

//supervisor search showroom list actions
export const FETCH_SUPERVISOR_SEARCH_SHOWROOM_LIST =
  "fetchSupervisorSearchShowroomList";

//theme actions
export const THEME = "theme";

//search
export const ADVANCE_FILTER = "advanceFilter";

//Clear actions
export const RESET_STATE = "resetState";
