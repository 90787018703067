//Collections Mutations
export const SET_COLLECTIONS = "setCollections";
export const SET_ALL_COLLECTIONS = "setAllCollections";
export const SET_FILTERED_COLLECTIONS = "setFilteredCollections";
export const SET_IS_LOADING_COLLECTIONS = "setIsLoadingCollections";
export const SET_NEXT_COLLECTION_PAGE = "setNextCollectionPage";
export const SET_IS_LOADING_NEXT_PAGE = "setIsLoadingNextPage";
export const SET_FILTER_NEXT_COLLECTION_PAGE = "setFilterNextCollectionPage";

//ShowRoomComercial Mutations
export const SET_SHOWROOM_COMERCIAL = "setShowRoomComercial";
export const SET_ERROR_SHOWROOM_COMERCIAL = "setErrorShowRoomComercial";
export const SET_IS_LOADING_SHOWROOM_COMERCIAL =
  "setIsLoadingShowRoomComercial";

//showroom dashboard mutations
export const ADD_FETCHED_DATA_TO_SHOWROOM_LIST = "addFetchedDataToShowRoomList";
export const SET_TO_BE_PRESENTED_SHOWROOM_LIST = "setToBePreparedShowroomList";
export const SET_DASHBOARD_LOADINGS = "setDashBoardLoadings";
export const APPEND_SHOWROOM_TO_LIST = "appendShowRoomToList";
export const PATCH_SHOWROOM_DATA = "patchShowRoomData";
export const DELETE_SHOWROOM = "deleteShowRoom";

//supervisor showroom list mutations
export const ADD_FETCHED_DATA_TO_SUPERVISOR_SHOWROOM_LIST =
  "addFetchedDataToSupervisorShowroomList";

// search showroom list mutations
export const ADD_FETCHED_SEARCH_DATA_TO_SHOWROOM_LIST =
  "addFetchedSearchDataToShowroomList";
export const RESET_SEARCH = "resetSearch";

// supervisor search showroom list mutations
export const ADD_FETCHED_SEARCH_DATA_TO_SUPERVISOR_SHOWROOM_LIST =
  "addFetchedSearchDataToSupervisorShowroomList";
export const RESET_SUPERVISOR_SEARCH = "resetSearch";

//filter Mutations
export const SET_QUERY = "setQuery";

//Auth Mutations
export const SET_AUTH = "setAuth";
export const PURGE_AUTH = "purgueAuth";
export const SET_ERROR_AUTH = "setErrorAuth";
export const SET_IS_LOADING_AUTH = "setIsLoadingAuth";

//Status Mutations
export const REQUEST_END = "fetchEnd";
export const REQUEST_START = "fetchStart";

//Viewer
export const SET_VIEWER_ITEM = "setViewerItem";
export const SET_VIEWER_MEDIA = "setViewerMedia";
export const SET_IS_LOADING_VIEWER = "setIsLoadingViewer";

//custom theme
export const SET_LOGO = "setLogo";

//search
export const SET_PRODUCTS_COLOR = "setProductsColor";
export const SET_PRODUCTS_FAMILIES = "setProductsFamilies";
export const SET_PRODUCTS_FEATURES = "setProductsFeatures";
export const SET_PRODUCTS_FORMAT_CM = "setProductsFormatCM";
export const SET_PRODUCTS_NUANCES = "setProductsNuances";
export const SET_PRODUCTS_SOLUTIONS = "setProducstSolutions";

//Clear Mutation
export const SET_CLEAR_STATE = "setClearState";
