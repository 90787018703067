import { UPDATE_VIEWER_ITEM } from "./actions.type";
import { UPDATE_VIEWER_MEDIA, RESET_STATE } from "./actions.type";

import { SET_VIEWER_ITEM } from "./mutations.type";
import { SET_VIEWER_MEDIA, SET_CLEAR_STATE } from "./mutations.type";

const getDefaultState = () => {
  return {
    viewerItem: {
      id: 0,
      collection: {},
      media: "",
      url: "",
      description: ""
    }
  };
};

const state = {
  viewerItem: {
    id: 0,
    collection: {},
    media: "",
    url: "",
    description: ""
  }
};

const getters = {
  viewerItem(state) {
    return state.viewerItem;
  }
};

const actions = {
  [UPDATE_VIEWER_ITEM]({ commit }, item) {
    return commit(SET_VIEWER_ITEM, item);
  },
  [UPDATE_VIEWER_MEDIA]({ commit }, newMedia) {
    return commit(SET_VIEWER_MEDIA, newMedia);
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};

const mutations = {
  [SET_VIEWER_ITEM](state, item) {
    state.viewerItem.id = item.id || -1;
    state.viewerItem.collection = item;
    state.viewerItem.media = "image";
    state.viewerItem.url = item.images[0].url;
    state.viewerItem.description = "";
  },
  [SET_VIEWER_MEDIA](state, newMedia) {
    state.viewerItem.media = newMedia.media;
    state.viewerItem.url = newMedia.url;
    state.viewerItem.description = newMedia.description || "";
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
