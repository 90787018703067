import { SalesRoomService } from "@/common/api.service.js";
import { FETCH_SEARCH_SHOWROOM_LIST, RESET_STATE } from "./actions.type";
import {
  ADD_FETCHED_SEARCH_DATA_TO_SHOWROOM_LIST,
  SET_CLEAR_STATE,
  RESET_SEARCH
} from "./mutations.type";

const getDefaultState = () => {
  return {
    searchShowroomList: [],
    searchShowroomListCount: 0,
    searchShowroomListPage: 1,
    searchShowroomListFetchStatus: "no-initial-fetch"
  };
};

const state = {
  searchShowroomList: [],
  searchField: null,
  searchShowroomListCount: 0,
  searchShowroomListPage: 1,
  searchShowroomListFetchStatus: "no-initial-fetch"
};

const getters = {
  searchShowroomList(state) {
    return state.searchShowroomList;
  },
  searchShowroomListFetchStatus() {
    return state.searchShowroomListFetchStatus;
  },
  searchShowroomListCount() {
    return state.searchShowroomListCount;
  }
};

const actions = {
  async [FETCH_SEARCH_SHOWROOM_LIST](
    { commit, state, dispatch },
    { page, search }
  ) {
    page = page || 1;

    if (search !== state.searchField) commit(RESET_SEARCH, search);

    if (
      state.searchShowroomListFetchStatus === "no-more-data" ||
      page < state.searchShowroomListPage
    )
      return state.searchShowroomList;
    let response = await SalesRoomService.getSalesRoomList(
      state.searchShowroomListPage,
      search
    );
    commit(ADD_FETCHED_SEARCH_DATA_TO_SHOWROOM_LIST, response);
    if (page >= state.searchShowroomListPage)
      await dispatch(FETCH_SEARCH_SHOWROOM_LIST, page);
    return state.searchShowroomList;
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};

const mutations = {
  [ADD_FETCHED_SEARCH_DATA_TO_SHOWROOM_LIST](state, response) {
    /*  set showroom list and manage pagination data */
    let data = response.data;

    if (state.searchShowroomListFetchStatus === "no-initial-fetch") {
      state.searchShowroomListFetchStatus = "data-fetched";
    }

    state.searchShowroomListCount = data.count;

    state.searchShowroomListPage = state.searchShowroomListPage + 1;

    if (!data.next) state.searchShowRoomListFetchStatus = "no-more-data";

    const dateOrNull = str => (str ? new Date(str) : null);
    let showrooms = data.results.map(showroom => {
      let status = getStatus(showroom);

      return {
        ...showroom,
        effective_start: dateOrNull(showroom.effective_start),
        effective_end: dateOrNull(showroom.effective_end),
        scheduled_for: dateOrNull(showroom.scheduled_for),
        status
      };
    });
    state.searchShowroomList = [...state.searchShowroomList, ...showrooms];
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [RESET_SEARCH](state, search) {
    state.searchField = search;
    state.searchShowroomList = [];
    state.searchShowroomListCount = 0;
    state.searchShowroomListPage = 1;
    state.searchShowroomListFetchStatus = "no-initial-fetch";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

function getStatus(showroom) {
  let status = null;
  if (showroom.presented) status = "Presentado";
  else if (showroom.is_showing) status = "En presentación";
  else if (showroom.is_prepared) status = "Preparado";
  else if (showroom.to_be_prepared) status = "Por preparar";
  else if (showroom.expired) status = "Caducado";
  return status;
}
