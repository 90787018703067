import Vue from "vue/dist/vue.js";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import AddToCalendar from "vue-add-to-calendar";

Vue.use(AddToCalendar);

//Imports Ant Design framework
import Antd from "ant-design-vue";
// import "../less/themes/custom-theme.less";

//Import vue-youtube third part component
import VueYoutube from "vue-youtube";

//Import Fragment third part component
import Fragment from "vue-fragment";

//Import ApiService to fetch data from backend
import ApiService from "./common/api.service";

//Import css to viewer component
import "viewerjs/dist/viewer.css";

//Import Component to view high resolution images
import Viewer from "v-viewer";

// Import WebSocket
import VueNativeSock from "vue-native-websocket";

//Import Api url
import { API_URL } from "@/common/config.js";

//Import VueSession
import VueSession from "vue-session";

Vue.config.productionTip = false;

//Use Viewer HD component
Vue.use(Viewer);

//Use Ant Design framework
Vue.use(Antd);

//Use Component of youtube
Vue.use(VueYoutube);

//Use Fragment component
Vue.use(Fragment.Plugin);

//Use Websocket
Vue.use(VueNativeSock, `${API_URL}`, {
  connectManually: true,
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
});

const options = {
  persist: true
};

//Use Session in vue
Vue.use(VueSession, options);

//Init api service
ApiService.init();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
