import Vue from "vue/dist/vue.js";
import Vuex from "vuex";

import auth from "./auth.module";
import dashboard from "./showRoomDashboard.module";
import showRoomComercial from "./showRoomComercial.module";
import viewer from "./viewer.module";
import collections from "./collections.module";
import customTheme from "./customTheme.module";
import supervisorShowroomList from "./supervisorShowroomList.module";
import showroomSearch from "./showroomSearch.module";
import supervisorShowroomSearch from "./supervisorShowroomSearch.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    showRoomComercial,
    dashboard,
    viewer,
    collections,
    customTheme,
    supervisorShowroomList,
    showroomSearch,
    supervisorShowroomSearch
  }
});
