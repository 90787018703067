import { SalesRoomService } from "@/common/api.service.js";
import { FETCH_SUPERVISOR_SHOWROOM_LIST, RESET_STATE } from "./actions.type";
import {
  ADD_FETCHED_DATA_TO_SUPERVISOR_SHOWROOM_LIST,
  SET_CLEAR_STATE
} from "./mutations.type";

const getDefaultState = () => {
  return {
    supervisorShowRoomList: [],
    supervisorShowRoomListCount: 0,
    supervisorShowRoomListPage: 1,
    supervisorShowRoomListFetchStatus: "no-initial-fetch"
  };
};

const state = {
  supervisorShowRoomList: [],
  supervisorShowRoomListCount: 0,
  supervisorShowRoomListPage: 1,
  supervisorShowRoomListFetchStatus: "no-initial-fetch"
};

const getters = {
  supervisorShowRoomList(state) {
    return state.supervisorShowRoomList;
  },
  supervisorShowRoomListFetchStatus() {
    return state.supervisorShowRoomListFetchStatus;
  },
  supervisorShowRoomListCount() {
    return state.supervisorShowRoomListCount;
  }
};

const actions = {
  async [FETCH_SUPERVISOR_SHOWROOM_LIST](
    { commit, state, dispatch },
    page = 1
  ) {
    if (
      state.supervisorShowRoomListFetchStatus === "no-more-data" ||
      page < state.supervisorShowRoomListPage
    )
      return state.supervisorShowRoomList;

    let response = await SalesRoomService.getSupervisorSalesRoomList(
      state.supervisorShowRoomListPage
    );

    commit(ADD_FETCHED_DATA_TO_SUPERVISOR_SHOWROOM_LIST, response);
    if (page >= state.supervisorShowRoomListPage)
      await dispatch(FETCH_SUPERVISOR_SHOWROOM_LIST, page);
    return state.supervisorShowRoomList;
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};

const mutations = {
  [ADD_FETCHED_DATA_TO_SUPERVISOR_SHOWROOM_LIST](state, response) {
    /*  set showroom list and manage pagination data */

    let data = response.data;

    if (state.supervisorShowRoomListFetchStatus === "no-initial-fetch") {
      state.supervisorShowRoomListFetchStatus = "data-fetched";
    }

    state.supervisorShowRoomListCount = data.count;

    state.supervisorShowRoomListPage = state.supervisorShowRoomListPage + 1;

    if (!data.next) state.supervisorShowRoomListFetchStatus = "no-more-data";

    const dateOrNull = str => (str ? new Date(str) : null);

    let showrooms = data.results.map(showroom => {
      let status = getStatus(showroom);

      return {
        ...showroom,
        effective_start: dateOrNull(showroom.effective_start),
        effective_end: dateOrNull(showroom.effective_end),
        scheduled_for: dateOrNull(showroom.scheduled_for),
        status
      };
    });
    state.supervisorShowRoomList = [
      ...state.supervisorShowRoomList,
      ...showrooms
    ];
  },
  [SET_CLEAR_STATE](state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

function getStatus(showroom) {
  let status = null;
  if (showroom.presented) status = "Presentado";
  else if (showroom.is_showing) status = "En presentación";
  else if (showroom.is_prepared) status = "Preparado";
  else if (showroom.to_be_prepared) status = "Por preparar";
  else if (showroom.expired) status = "Caducado";
  return status;
}
