import { SalesRoomService } from "@/common/api.service.js";
import {
  FETCH_SHOWROOM_LIST,
  CREATE_SHOWROOM,
  START_SHOWROOM_PRESENTATION,
  LOAD_FULL_SHOWROOM_DATA,
  UPDATE_SHOWROOM,
  DEACTIVATE_SHOWROOM,
  RESET_STATE,
  FETCH_TO_BE_PRESENTED_SHOWROOM_LIST
} from "./actions.type";
import {
  ADD_FETCHED_DATA_TO_SHOWROOM_LIST,
  APPEND_SHOWROOM_TO_LIST,
  PATCH_SHOWROOM_DATA,
  SET_TO_BE_PRESENTED_SHOWROOM_LIST,
  SET_DASHBOARD_LOADINGS,
  DELETE_SHOWROOM,
  SET_CLEAR_STATE
} from "./mutations.type";

/*
  showRoomList is a list of the showrooms for a comercial when it is initially
  fetch with FETCH_SHOWROOM_LIST only the most important data is recovered
  to fetch all data you call LOAD_FULL_SHOWROOM_DATA
  when a shooroom is loading extra data its attribute  pending is set to true
*/

const getDefaultState = () => {
  return {
    showRoomList: [],
    showRoomListCount: 0,
    showRoomListPage: 1,
    showRoomListFetchStatus: "no-initial-fetch",
    toBePresentedShowroomList: null,
    isLoadingShowRoomList: false,
    isLoadingShowRoomCreation: false,
    isLoadingShowRoomPresent: false,
    isLoadingShowRoomUpdate: false,
    isLoadingShowRoomData: false
  };
};

const state = getDefaultState();

const getters = {
  showRoomList(state) {
    return state.showRoomList;
  },
  showRoomListFetchStatus() {
    return state.showRoomListFetchStatus;
  },
  showRoomListCount() {
    return state.showRoomListCount;
  },
  isLoadingShowRoomList(state) {
    return state.isLoadingShowRoomList;
  },
  isLoadingShowRoomPresent(state) {
    return state.isLoadingShowRoomPresent;
  },
  isLoadingShowRoomCreation(state) {
    return state.isLoadingShowRoomCreation;
  },
  isLoadingShowRoomUpdate(state) {
    return state.isLoadingShowRoomUpdate;
  },
  isLoadingShowRoomData(state) {
    return state.isLoadingShowRoomData;
  },
  getShowRoomById: (state, _, rootState) => id => {
    let showrooms = [
      ...state.showRoomList,
      ...rootState.supervisorShowroomList.supervisorShowRoomList,
      ...rootState.showroomSearch.searchShowroomList,
      ...rootState.supervisorShowroomSearch.supervisorSearchShowroomList
    ];
    return showrooms.find(sw => sw.id == id);
  },
  toBePresentedShowroomList(state) {
    return state.toBePresentedShowroomList;
  }
};

/* some actions return true or false depending on whether they succeed or fail */
const actions = {
  async [FETCH_TO_BE_PRESENTED_SHOWROOM_LIST]({ commit }) {
    let results = (await SalesRoomService.getToBePresentedShowrooms()).data
      .results;
    commit(SET_TO_BE_PRESENTED_SHOWROOM_LIST, results);
  },
  async [DEACTIVATE_SHOWROOM]({ commit, rootState }, id) {
    try {
      let response = await SalesRoomService.deactivateSalesRoom(id);
      commit(DELETE_SHOWROOM, [id, rootState]);
      return response.status === 204;
    } catch (err) {
      return false;
    }
  },
  async [UPDATE_SHOWROOM]({ commit, rootState }, { id, newData }) {
    commit(SET_DASHBOARD_LOADINGS, ["UPDATE", true]);
    let response = null;
    try {
      response = await SalesRoomService.updateSalesRoom(id, newData);
      let data = (await SalesRoomService.getFullSalesRoomData(id)).data;
      commit(PATCH_SHOWROOM_DATA, [id, data, rootState]);
    } catch (err) {
      response = err.response;
    }
    commit(SET_DASHBOARD_LOADINGS, ["UPDATE", false]);
    return response;
  },
  async [LOAD_FULL_SHOWROOM_DATA]({ commit, rootState }, id) {
    commit(SET_DASHBOARD_LOADINGS, ["LOAD_DATA", true]);
    let fulldata = null;
    if (
      [
        ...rootState.dashboard.showRoomList,
        ...rootState.showroomSearch.searchShowroomList
      ].find(s => s.id == id)
    )
      fulldata = (await SalesRoomService.getFullSalesRoomData(id)).data;
    else if (
      [
        ...rootState.supervisorShowroomList.supervisorShowRoomList,
        ...rootState.supervisorShowroomSearch.supervisorSearchShowroomList
      ].find(s => s.id == id)
    )
      fulldata = (await SalesRoomService.getFullSupervisorSalesRoomData(id))
        .data;
    commit(PATCH_SHOWROOM_DATA, [id, fulldata, rootState]);
    commit(SET_DASHBOARD_LOADINGS, ["LOAD_DATA", false]);
  },
  async [FETCH_SHOWROOM_LIST]({ commit, state, dispatch }, page = 1) {
    if (
      state.showRoomListFetchStatus === "no-more-data" ||
      page < state.showRoomListPage
    )
      return state.showRoomList;
    commit(SET_DASHBOARD_LOADINGS, ["LIST", true]);
    let response = await SalesRoomService.getSalesRoomList(
      state.showRoomListPage
    );
    commit(ADD_FETCHED_DATA_TO_SHOWROOM_LIST, response);
    commit(SET_DASHBOARD_LOADINGS, ["LIST", false]);
    if (page >= state.showRoomListPage)
      await dispatch(FETCH_SHOWROOM_LIST, page);
    return state.showRoomList;
  },

  /*to create showroom*/
  async [CREATE_SHOWROOM]({ commit, dispatch }, data) {
    commit(SET_DASHBOARD_LOADINGS, ["CREATION", true]);
    let response;
    try {
      response = await SalesRoomService.createSalesRoom(data);
      commit(APPEND_SHOWROOM_TO_LIST, response.data);
      await dispatch(LOAD_FULL_SHOWROOM_DATA, response.data.id);
    } catch (err) {
      response = err.response;
    }
    commit(SET_DASHBOARD_LOADINGS, ["CREATION", false]);
    return response;
  },

  async [START_SHOWROOM_PRESENTATION]({ commit, rootState }, id) {
    commit(SET_DASHBOARD_LOADINGS, ["PRESENT", true]);
    let response = null;
    try {
      response = await SalesRoomService.startSalesRoom(id);
      commit(PATCH_SHOWROOM_DATA, [
        id,
        {
          is_showing: true,
          effective_start: new Date(response.data.effective_start),
          status: "En presentación"
        },
        rootState
      ]);
    } catch (err) {
      response = err.response;
    }
    commit(SET_DASHBOARD_LOADINGS, ["PRESENT", false]);
    return response;
  },
  [RESET_STATE]({ commit }) {
    commit(SET_CLEAR_STATE);
  }
};

const mutations = {
  [SET_TO_BE_PRESENTED_SHOWROOM_LIST](state, showrooms) {
    state.toBePresentedShowroomList = showrooms;
  },
  [DELETE_SHOWROOM](state, [id, rootState]) {
    state.showRoomListCount--;
    let ushowrooms = state.showRoomList;
    let sushowrooms = rootState.showroomSearch.searchShowroomList;

    state.showRoomList = ushowrooms.filter(s => s.id !== id);

    rootState.showroomSearch.searchShowroomList = sushowrooms.filter(
      s => s.id !== id
    );
  },
  [SET_DASHBOARD_LOADINGS](state, [type, value]) {
    if (type == "UPDATE") state.isLoadingShowRoomUpdate = value;
    else if (type == "CREATION") state.isLoadingShowRoomCreation = value;
    else if (type == "PRESENT") state.isLoadingShowRoomPresent = value;
    else if (type == "LOAD_DATA") state.isLoadingShowRoomData = value;
    else if (type == "LIST") state.isLoadingShowRoomList = value;
  },
  [APPEND_SHOWROOM_TO_LIST](state, newShowRoom) {
    state.showRoomListCount++;
    if (newShowRoom.effective_start)
      newShowRoom.effective_start = new Date(newShowRoom.effective_start);
    if (newShowRoom.effective_end)
      newShowRoom.effective_end = new Date(newShowRoom.effective_end);
    if (newShowRoom.scheduled_for)
      newShowRoom.scheduled_for = new Date(newShowRoom.scheduled_for);

    newShowRoom.scheduled_series = null;

    newShowRoom.status = getStatus(newShowRoom);

    newShowRoom.seller = { email: this._vm.$session.get("user").email };

    state.showRoomList = [...state.showRoomList, newShowRoom];
  },
  [PATCH_SHOWROOM_DATA](state, [id, data, rootState]) {
    /* this patch will be applied to all possibles lists */
    let ushowrooms = state.showRoomList;
    let sshowrooms = rootState.supervisorShowroomList.supervisorShowRoomList;
    let sushowrooms = rootState.showroomSearch.searchShowroomList;
    let ssshowrooms =
      rootState.supervisorShowroomSearch.supervisorSearchShowroomList;

    if (ushowrooms.find(s => s.id == id))
      state.showRoomList = ushowrooms.map(transformList);
    if (sshowrooms.find(s => s.id == id))
      rootState.supervisorShowroomList.supervisorShowRoomList = sshowrooms.map(
        transformList
      );
    if (sushowrooms.find(s => s.id == id))
      rootState.showroomSearch.searchShowroomList = sushowrooms.map(
        transformList
      );
    if (ssshowrooms.find(s => s.id == id))
      rootState.supervisorShowroomSearch.supervisorSearchShowroomList = ssshowrooms.map(
        transformList
      );
    /*  sets the list to a new list identical to the previus
    but with the showroom with the id passed edited with the new data */
    function transformList(sh) {
      if (sh.id != id) return sh;

      data.status = getStatus(data);

      if (data.effective_start)
        data.effective_start = new Date(data.effective_start);
      if (data.effective_end) data.effective_end = new Date(data.effective_end);
      if (data.scheduled_for) data.scheduled_for = new Date(data.scheduled_for);
      return {
        ...sh,
        ...data
      };
    }
  },
  [ADD_FETCHED_DATA_TO_SHOWROOM_LIST](state, response) {
    /*  set showroom list and manage pagination data */

    let data = response.data;

    if (state.showRoomListFetchStatus === "no-initial-fetch") {
      state.showRoomListFetchStatus = "data-fetched";
    }

    state.showRoomListCount = data.count;
    state.showRoomListPage++;

    if (!data.next) state.showRoomListFetchStatus = "no-more-data";

    let showrooms = data.results.map(showroom => {
      return {
        ...showroom,
        effective_start: dateOrNull(showroom.effective_start),
        effective_end: dateOrNull(showroom.effective_end),
        scheduled_for: dateOrNull(showroom.scheduled_for),
        status: getStatus(showroom)
      };
    });

    state.showRoomList = [...state.showRoomList, ...showrooms];
  },
  [SET_CLEAR_STATE](state) {
    state.toBePresentedShowroomList = null;
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

/* utility functions */

function dateOrNull(str) {
  return str ? new Date(str) : null;
}

function getStatus(showroom) {
  let status = null;
  if (showroom.presented) status = "Presentado";
  else if (showroom.is_showing) status = "En presentación";
  else if (showroom.is_prepared) status = "Preparado";
  else if (showroom.to_be_prepared) status = "Por preparar";
  else if (showroom.expired) status = "Caducado";
  return status;
}
